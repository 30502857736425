import React, { useMemo } from 'react';
import { PanelProps } from '@grafana/data';
import { useTheme2, Tooltip} from '@grafana/ui';
import { css } from '@emotion/css';

interface BatteryPanelOptions {
  // No custom options needed if using standard data source
}

export const BatteryPanel: React.FC<PanelProps<BatteryPanelOptions>> = ({ 
  data, 
  width, 
  height 
}) => {
  const theme = useTheme2();
  const styles = getStyles(theme);

  // Safer and more flexible data extraction
  const extractNumericValue = (frame: any, fieldName: string, defaultValue: number = 0): number => {
    try {
      // Check if frame and fields exist
      if (!frame?.fields?.length) {
        return defaultValue;
      }

      // Find the field by name (case-insensitive)
      const field = frame.fields.find((f: any) => 
        f.name?.toLowerCase() === fieldName.toLowerCase()
      );

      // If no field found, try first field as fallback
      const targetField = field || frame.fields[0];

      // Extract first value safely
      const value = targetField.values.get(0);
      return Number(value ?? defaultValue);
    } catch (error) {
      console.error('Error extracting numeric value:', error);
      return defaultValue;
    }
  };

  // Memoize data extraction to prevent unnecessary re-renders
  const batteryData = useMemo(() => {
    if (!data?.series?.length) {
      return null;
    }

    // Use the first series and try to extract by field name
    const series = data.series[0];

    return {
      maxCapacity: extractNumericValue(series, 'max_capacity', 100),
      soc: extractNumericValue(series, 'soc', 0),
      levlSoc: extractNumericValue(series, 'levl_soc', 0),
      pucSoc: extractNumericValue(series, 'puc_soc', 0)
    };
  }, [data]);

  // If no data, render placeholder
  if (!batteryData) {
    return <div className={styles.wrapper}>No data available</div>;
  }

  const { maxCapacity, soc, levlSoc, pucSoc } = batteryData;

  const batteryHeight = height - 100; // Reserve space for legend
  const batteryWidth = width * 0.4;
  const batteryInnerHeight = batteryHeight - 40;

  // Ensure heights are calculated correctly
  const socHeight = Math.max(0, (soc / maxCapacity) * batteryInnerHeight);
  const pucHeight = Math.max(0, (pucSoc / maxCapacity) * batteryInnerHeight);

  const renderBatteryAreas = () => {
    // Ensure we handle cases where values might be 0 or very small
    if (soc === 0 && pucSoc === 0) {
      return null;
    }

    if (soc >= pucSoc) {
      return (
        <>
          {/* Grey area under PUC line */}
          <div 
            className={styles.batteryArea}
            style={{ 
              height: Math.max(0, pucHeight), 
              backgroundColor: 'rgba(158, 159, 172, 0.5)',
	            bottom:0.1
            }}
          />
          {/* Blue area between lines */}
                  <div 
          className={styles.batteryArea}
          style={{ 
            height: Math.max(0, socHeight - pucHeight),
            bottom: pucHeight+3,
            backgroundImage: 'repeating-linear-gradient(45deg, rgba(25, 47, 249, 0.5), rgba(25, 47, 249, 0.5) 15px, rgba(255, 255, 255, 1) 5px, rgba(255, 255, 255, 1) 20px)'
          }}
        />
        </>
      );
    }
    
    return (
      <>
        {/* Grey area under SoC line */}
        <div 
          className={styles.batteryArea}
          style={{ 
            height: Math.max(0, socHeight), 
            backgroundColor: 'rgba(158, 159, 172, 0.5)', 
	          bottom:0.1
          }}
        />
        {/* Blue area between lines */}
        <div 
          className={styles.batteryArea}
          style={{ 
            height: Math.max(0, pucHeight - socHeight-3), 
            bottom: socHeight+3,
            backgroundImage: 'repeating-linear-gradient(45deg, rgba(25, 47, 249, 0.5), rgba(25, 47, 249, 0.5) 15px, rgba(158,159,172,0.5) 5px, rgba(158, 159, 172, 0.5) 20px)'
          }}
        />
      </>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.batteryContainer}>
        <Tooltip
          content={
            <div>
              <div>Gesamtkapazität: {maxCapacity.toFixed(2)} kWh</div>
              <div>SoC: {soc.toFixed(2)} kWh</div>
              <div>PUC SoC: {pucSoc.toFixed(2)} kWh</div>
              <div>Levl SoC: {levlSoc.toFixed(2)} kWh</div>
            </div>
          }
          placement="top"
        >
          <div style={{ position: 'relative', width: batteryWidth, height: batteryHeight }}>
            <div className={styles.batteryOuter}>
              <div className={styles.batteryTop}></div>
              <div className={styles.batteryInner}>
                {renderBatteryAreas()}
                
                {/* SoC Blue Line */}
                <div 
                  className={styles.batteryLine}
                  style={{ 
                    bottom: socHeight,
                    borderBottomColor: 'rgb(25, 47, 249)',
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '3px'
                  }}
                />
                
                {/* PUC SoC Dashed Grey Line */}
                <div 
                  className={styles.batteryLine}
                  style={{ 
                    bottom: pucHeight,
                    borderBottomColor: 'rgb(93, 95, 112)',
                    borderBottomStyle: 'dashed'
                  }}
                />
              </div>
            </div>
          </div>
        </Tooltip>
      </div>
      <div className={styles.legendContainer}>
        <div className={styles.legendItem}>
          <div className={styles.socLineLegend}></div>
          <span>SoC</span>
        </div>
        <div className={styles.legendItem}>
          <div className={styles.pucSocLineLegend}></div>
          <span>PUC SoC</span>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: any) => ({
  wrapper: css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
  `,
  batteryContainer: css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
  batteryOuter: css`
    position: absolute; 
    inset: 0; 
    background-color: white; 
    border: 4px solid black; 
    border-radius: 1rem; 
    box-shadow: inset 0 2px 4px rgba(0,0,0,0.1);
  `,
  batteryTop: css`
    position: absolute; 
    top: 0; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 4rem; 
    height: 1rem; 
    background-color: black; 
    border-radius: 0.5rem 0.5rem 0 0;
  `,
  batteryInner: css`
    position: absolute; 
    inset: 0.5rem; 
    background-color: white; 
    border-radius: 1rem; 
    overflow: hidden;
  `,
  batteryArea: css`
    position: absolute;
    left: 0;
    right: 0;
  `,
  batteryLine: css`
    position: absolute;
    left: 0;
    right: 0;
    border-bottom-width: 3px;
    z-index: 10; // Ensure lines are on top
  `,
  legendContainer: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  `,
  legendItem: css`
    display: flex;
    align-items: center;
    margin: 0 10px;
  `,
  socLineLegend: css`
    width: 30px;
    height: 3px;
    background-color: rgb(25, 47, 249);
    margin-right: 5px;
  `,
  pucSocLineLegend: css`
    width: 30px;
    height: 3px;
    background-color: rgb(128, 128, 128);
    border-bottom: 1px dashed;
    margin-right: 5px;
  `,
});
